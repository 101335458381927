:root {
  --background-color: #f9fafc;
  --text-color: #333;
  --navbar-background-color: #ffffff;
  --navbar-text-color: #333;
  --navbar-hover-color: #ff6f61;
  --border-color: #b3b3b3;
  --accent-color: #ff6f61;
  --purple-color: #6c63ff;
  --grey-color: #828282;
}

.dark-mode {
  --background-color: #002b36;
  --text-color: #eee8d5;
  --navbar-background-color: #073642;
  --navbar-text-color: #eee8d5;
  --navbar-hover-color: #b58900;
  --border-color: #586e75;
  --accent-color: #2aa198;
  --purple-color: #b58900;
  --grey-color: #93a1a1;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
  background-image: radial-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 10px 10px;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--navbar-background-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-nav .nav-link {
  color: var(--navbar-text-color);
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: var(--navbar-hover-color);
}

.left {
  font-size: 1.5rem;
  animation: slideinleft 10s;
}

.firstsection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 80px 0;
}

.leftsection {
  width: 30%;
  font-size: 2rem;
}

.rightsection {
  width: 30%;
}

.rightsection img {
  border-radius: 100px;
  width: 80%;
}

main hr {
  border: 0;
  background: var(--border-color);
  height: 1.8px;
  margin: 60px 60px 40px 40px;
}

.purple {
  color: var(--purple-color);
}

.text-grey {
  color: var(--grey-color);
}

#element {
  color: var(--accent-color);
}

/* For Studies.jsx */
.timeline {
  display: flex;
  justify-content: space-around;
}

.secondsection {
  max-width: 80vw;
  margin: auto;
}

.secondsection h1 {
  font-size: 1.5rem;
}

.studysection1, .studysection2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
}

.firstchild {
  width: 40%;
  height: auto;
  border: 1px solid var(--border-color);
  padding: 10px;
  border-radius: 20px 0px 20px 0px;
}

.secondchild {
  width: 40%;
  height: auto;
  border: 1px solid var(--border-color);
  padding: 10px;
  border-radius: 20px 0px 20px 0px;
}

.container-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  gap: 10px;
}

.griditem {
  border: 1px solid var(--border-color);
  padding: 10px;
  gap: 10px;
  text-align: center;
}

#about-image {
  width: 50%;
  width: 200px;
  border: 1px solid var(--border-color);
  border-radius: 1000px;
}

#Tech-grid {
  display: grid;
  grid-template-columns: auto auto auto auto ;
}

#tech {
  background-color: var(--navbar-background-color);
  color: var(--text-color);
  border-radius: 1000px;
  border: 2px dotted var(--border-color);
  text-align: center;
  padding: 10px;
}

.end {
  text-align: center;
  margin-top: 20px;
  color: #828282;
  font-size: 1.2rem;
  background-color: whitesmoke;
}

.fa {
  padding: 20px;
  font-size: 30px;
  width: 80px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: red;
  color: white;
}

.whats-app {
  position: fixed; 
  bottom: 50px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 1px;
  box-shadow: 3px 4px 3px #999;
  left: 1px;
  z-index: 100;
}

/* Responsive Adjustments */
@media only screen and (max-width: 768px) {
  .firstsection {
    flex-direction: column;
    margin: 40px 0;
  }
  
  .leftsection, .rightsection {
    width: 100%;
    text-align: center;
  }
  
  .leftsection {
    font-size: 1.5rem;
  }
  
  .rightsection img {
    width: 70%;
  }
}

/* For even smaller screens */
@media only screen and (max-width: 480px) {
  /* Specific styles for very small screens */
  .firstsection {
    flex-direction: column;
    align-items: center;
  }
  
  .leftsection, .rightsection {
    width: 100%;
    text-align: center;
  }

  .navbar-nav {
    flex-direction: column;
    text-align: center;
  }

  .navbar-nav .nav-item {
    margin: 10px 0;
  }

  .navbar-nav .nav-link {
    padding: 10px;
    width: 100%;
  }
}

/* Keyframes (if needed) */
@keyframes slideinleft {
  from {
    transform: translateX(-300px);
  }
  to {
    transform: translateX(0px);
  }
}
